/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import { CrfCondition, Field, FieldGroup, FormOptionsContext, ICrfConditionParameters, InstitutionContext, InstitutionsContext, KeyboardDatePicker, PatientsContext, Select, Text, TextArea, ToggleButtonGroup } from '@ngt/opms';
import { ScreeningLogContext } from '@ngt/opms-screeninglog';
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { MenuItem, Theme, fade, makeStyles } from '@material-ui/core';
import { usePermissionsByIds } from '@ngt/opms-bctapi';
import * as Dtos from '../../api/dtos';
import { LookupGenderType, LookupScreeningLogReasonType } from '../../api/dtos';
import { getGenderValue, getReasonValue } from '../../hooks/screeninglogs/useScreenFailsColumns';
import onlinePatientManagement from '../../onlinePatientManagement';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogFieldGroupProps {
}

interface IMenuItem {
    id?: number;
    value?: string;
    label?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    disabled: {
        background: '#f2f2f2'
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    button: {
        width: '100%',
        height: 32,

        [theme.breakpoints.down('md')]: {
            height: '100%'
        }
    },
    buttonGroup: {
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(1.5)
    },
    text: {
        paddingBottom: theme.spacing(1.5)
    }
}));

const useButtonStyles = makeStyles(theme => ({
    root: {
        color: `${theme.palette.common.black} !important`,

        marginRight: (props: any) => props.orientation !== 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',
        marginBottom: (props: any) => props.orientation === 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',

        borderLeftColor: (props: any) => props.orientation === 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',
        borderTopColor: (props: any) => props.orientation !== 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',

        borderRadius: (props: any) => props.spacing ? `${theme.shape.borderRadius}px !important` : '',

        '&:last-child': {
            marginRight: 0,
            marginBottom: 0
        }
    },
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`
    },
    disabled: {
        '&$selected': {
            backgroundColor: `${fade(theme.palette.primary.main, 0.3)} !important`,
            color: `${fade(theme.palette.common.black, 0.74)} !important`
        },
        '&$disabled': {
            color: `${fade(theme.palette.common.black, 0.5)} !important`
        }
    },
    label: {
        textTransform: 'none'
    }
}));

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/
const isScreenFailOrStatusFailed = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.outcome === Dtos.ScreeningOutcome.ScreenFail || (formState?.values.status === Dtos.ScreeningStatus.Failed && formState?.values.patientId !== null);
const isProceedingToRegistration = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.outcome === Dtos.ScreeningOutcome.ProceedToRegistration;
const isPatientUsingEconsent = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.patientUsingEconsent === Dtos.LookupYesNoType.Yes;
const hasPatientId = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => !!formState?.values.patientId;

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const permissions: Dtos.Permission[] = [
    Dtos.Permission.EConsentAdminister
];

const ScreeningLogFieldGroup = () => {
    const classes = useStyles();

    const buttonClasses = useButtonStyles();

    const { patients } = React.useContext(PatientsContext);

    const { readOnly } = React.useContext(FormOptionsContext);

    const { institutions } = React.useContext(InstitutionsContext);

    const { institution: selectedInstitution } = React.useContext(InstitutionContext);

    const { screeningLog } = React.useContext(ScreeningLogContext);

    const institution = React.useMemo(() => {        
        return institutions?.find(i => i.id === (screeningLog?.institutionId ?? selectedInstitution?.id))
    }, [institutions, screeningLog, selectedInstitution]);

    const [[canAdministerEConsent], , ] = usePermissionsByIds(permissions, null, null, institution?.id, null, !!institution?.id);

    const client = onlinePatientManagement?.serviceStackClient;

    const [investigators, setInvestigators] = React.useState<IMenuItem[]>([]);

    // get the list of investigators based on the institutioncode
    React.useEffect(() => {
        client
            .get(new Dtos.InvitationGetInvestigators({
                institutionCode: institution?.code
            }))
            .then(response => {
                let items: IMenuItem[] = [];

                Object.keys(response.investigators).forEach((key, index) => {

                    let item: IMenuItem = {
                        id: index,
                        value: key,
                        label: response.investigators[key]
                    }

                    items.push(item);
                });

                setInvestigators(items);
            })
            .catch((e) => {
                
            });
    }, [client, institution, setInvestigators]);

    return (
        <>
            <Field
                name="screeningDate"
                component={KeyboardDatePicker}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
            />
            <Field
                name="initials"
                component={Text}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
            />
            <Field
                name="dateOfBirth"
                component={KeyboardDatePicker}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
            />
            <Field
                name="gender"
                component={Select}
                sm={12}
                md={6}
                xl={6}
                lg={6}
                simpleLabel
            >
                <MenuItem
                    key={LookupGenderType.Female}
                    value={LookupGenderType.Female}
                >
                    {getGenderValue(LookupGenderType.Female)}
                </MenuItem>
                <MenuItem
                    key={LookupGenderType.Male}
                    value={LookupGenderType.Male}
                >
                    {getGenderValue(LookupGenderType.Male)}
                </MenuItem>
                <MenuItem
                    key={LookupGenderType.NonBinary}
                    value={LookupGenderType.NonBinary}
                >
                    {getGenderValue(LookupGenderType.NonBinary)}
                </MenuItem>
            </Field>
            <FieldGroup
                paddingTop={0}
                paddingBottom={0}
            >
                <Field
                    name="outcome"
                    component={ToggleButtonGroup}
                    sm={12}
                    md={6}
                    xl={6}
                    lg={6}
                    simpleLabel
                    className={classes.buttonGroup}
                >
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.ScreeningOutcome.ProceedToRegistration}
                        value={Dtos.ScreeningOutcome.ProceedToRegistration}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        PROCEED TO REGISTRATION
                    </MuiToggleButton>
                    <MuiToggleButton
                        disabled={readOnly}
                        key={Dtos.ScreeningOutcome.ScreenFail}
                        value={Dtos.ScreeningOutcome.ScreenFail}
                        className={classes.button}
                        classes={buttonClasses}
                    >
                        SCREEN FAIL
                    </MuiToggleButton>
                </Field>
                <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={hasPatientId}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <Field
                        name="patientId"
                        component={Text}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        label="Study number"
                        convert={(patientId) => patients?.find(p => p.id === patientId)?.studyNumber}
                        simpleLabel
                        paddingBottom={2.5}
                    />
                </CrfCondition>
                <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={isScreenFailOrStatusFailed}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <Field
                        name="reason"
                        component={Select}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        simpleLabel
                        paddingBottom={2}
                    >
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantIneligible}
                            value={LookupScreeningLogReasonType.ParticipantIneligible}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantIneligible)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ClinicianDeemedUnsuitable}
                            value={LookupScreeningLogReasonType.ClinicianDeemedUnsuitable}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ClinicianDeemedUnsuitable)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantDeclinedTrialStandardCare}
                            value={LookupScreeningLogReasonType.ParticipantDeclinedTrialStandardCare}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantDeclinedTrialStandardCare)}
                        </MenuItem>
                        <MenuItem
                            key={LookupScreeningLogReasonType.ParticipantDeclinedTrialNoReason}
                            value={LookupScreeningLogReasonType.ParticipantDeclinedTrialNoReason}
                        >
                            {getReasonValue(LookupScreeningLogReasonType.ParticipantDeclinedTrialNoReason)}
                        </MenuItem>
                    </Field>
                    <Field
                        name="reasonSpecify"
                        component={TextArea}
                        variant="outlined"
                        multiline={true}
                        rows={5}
                        sm={12}
                        md={6}
                        xl={6}
                        lg={6}
                        simpleLabel
                        InputProps={{
                            classes: {
                                disabled: classes.disabled
                            }
                        }}
                    />
                </CrfCondition>
            </FieldGroup>
            {
                institution?.eConsentActivationDate && canAdministerEConsent && <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={isProceedingToRegistration}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <FieldGroup
                        paddingTop={0}
                        paddingBottom={0}
                    >
                        <Field
                            name="patientUsingEconsent"
                            component={ToggleButtonGroup}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            simpleLabel
                        >
                            <MuiToggleButton
                                disabled={readOnly}
                                key={Dtos.LookupYesNoType.Yes}
                                value={Dtos.LookupYesNoType.Yes}
                                className={classes.button}
                                classes={buttonClasses}
                            >
                                Yes
                            </MuiToggleButton>
                            <MuiToggleButton
                                disabled={readOnly}
                                key={Dtos.LookupYesNoType.No}
                                value={Dtos.LookupYesNoType.No}
                                className={classes.button}
                                classes={buttonClasses}
                            >
                                No
                            </MuiToggleButton>
                        </Field>
                        <CrfCondition
                            type={Dtos.ScreeningLog}
                            condition={isPatientUsingEconsent}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="econsentEmail"
                                component={Text}
                                sm={12}
                                md={6}
                                xl={6}
                                lg={6}
                                simpleLabel
                                paddingBottom={3}
                            />
                            <Field
                                name="econsentInvestigator"
                                component={Select}
                                sm={12}
                                md={6}
                                xl={6}
                                lg={6}
                                simpleLabel
                                paddingBottom={3}
                            >
                                {
                                    investigators?.map(i => {
                                        return <MenuItem
                                            key={i.value}
                                            value={i.value}
                                        >
                                            {i.label}
                                        </MenuItem>
                                    })
                                }
                            </Field>

                        </CrfCondition>
                    </FieldGroup>
                </CrfCondition>
            }
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogFieldGroup;