/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Text,
    Select,
    FormGrid,
    Input,
    FileUpload,
    IFormGridCell,
    IFileUpload,
    FieldGroup,
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { makeStyles, Typography } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ISourceDocumentsProps {
    instructions?: React.ReactNode;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * constant
 * ---------------------------------------------------------------------------------
 */
const existingLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/download/${file?.id}`;
}

const columns: Array<IFormGridCell<Dtos.SourceDocument>> = [
    {
        name: 'name',
        content: (
            <Input component={Text} />
        )
    },
    {
        name: 'fileTypeId',
        content: (
            <Input component={Select} />
        )
    },
    {
        name: 'file',
        content: (
            <Input
                component={FileUpload}
                existingLink={existingLink}
                existingDownloadLink={existingDownloadLink}
                targetUrl={(window as any).REDACT_URL}
            />
        )
    }
]

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const SourceDocuments: React.FunctionComponent<ISourceDocumentsProps> = ({
    instructions
}) => {
    const classes = useStyles();


    return (
        <>
            <FieldGroup>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    Source Documents
                </Typography>
                {
                    !!instructions && instructions
                }
                <FormGrid
                    name="sourceDocuments"
                    columns={columns}
                    type={Dtos.SourceDocument}
                    rowLabel="Source Document"
                    rowValueAccessor="name"
                />
            </FieldGroup>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default SourceDocuments;
