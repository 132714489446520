export interface IReportSettings {
    reportServerUrl: string;
    reportViewUrl: string;
    reportDownloadUrl: string;
    reportFolder: string;
    saeReport: string;
}

export enum ReportExportFormat {
    Pdf = 0,
    Word = 1,
    Excel = 2
};

export interface IReportParam {
    paramName: string;
    paramValue: string;
}

const reportSettings: IReportSettings = (global as any).REPORT_SETTINGS;

/**
 * This function constructs a string for a report.
 * @param {string} reportName The name of the report file.
 * @param {IReportParam[]} reportParams Array of report parameters.
 * @param {ReportExportFormat} exportAs Optional export format. If omitted, will just show report in a webpage.
 * @param {boolean} showToolbar Optional parameter indicating whether or not to show the toolbar. Shows toolbar if omitted.
 */
export const createReportUrl = (reportName: string,
    reportParams: IReportParam[],
    exportAs?: ReportExportFormat,
    showToolbar?: boolean): string => {

    let reportUrl: string = '';

    const reportFolder = exportAs !== null && exportAs !== undefined ?
        reportSettings.reportDownloadUrl :
        reportSettings.reportViewUrl;

    reportUrl = `${reportSettings.reportServerUrl}/${reportFolder}?/${reportSettings.reportFolder}/${reportName}`;

    if (showToolbar !== undefined &&
        !showToolbar) {
        reportUrl += '&rc:Toolbar=false';
    }

    if (exportAs !== undefined) {
        reportUrl += '&rs:ClearSession=true&rs:Format=';

        if (exportAs === ReportExportFormat.Pdf) {
            reportUrl += 'PDF';
        }
        else if (exportAs === ReportExportFormat.Excel) {
            reportUrl += 'EXCELOPENXML';
        }
        else if (exportAs === ReportExportFormat.Word) {
            reportUrl += 'WORDOPENXML';
        }
    } else {
        reportUrl += '&rs:Command=Render&rs:ClearSession=true';
    }

    for (const reportParam of reportParams) {
        reportUrl += '&' + reportParam.paramName + '=' + reportParam.paramValue;
    }

    return reportUrl;
};