/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    CrfForm,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormContext,
    FormGrid,
    IFormActions,
    IFormGridCell,
    IFormState,
    Input,
    InstitutionContext,
    MasterGroupContext,
    OnlinePatientManagementContext,
    PageLayout,
    PatientContext,
    RouteLoading,
    SubmitButton,
    TextArea,
    useFormActions,
    useFormDefinitions,
    usePatientValidationByStudyNumber,
    useSnackbar
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Button, Paper, Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import AlertTitle from '@material-ui/lab/AlertTitle';
import pluralize from 'pluralize';
import { useHistory, useParams } from 'react-router-dom';
import * as Dtos from '../../api/dtos';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientStatusFormParams {
    institutionCode?: string,
    patientStudyNumber?: string;
}

interface IPatientStatusFormProps {
}

interface IPatientStatusFormFieldsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister
];

const PatientStatusCriteriaColumns: Array<IFormGridCell<Dtos.PatientStatusCriteria>> = [
    {
        name: 'formName',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
    {
        name: 'fieldName',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
    {
        name: 'details',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
];

const PatientStatusFormFields: React.FunctionComponent<IPatientStatusFormFieldsProps> = ({
}) => {
    const classes = useStyles();

    const { patient } = React.useContext(PatientContext);

    const { setFieldValue } = useFormActions();

    const [patientValidation, , ] = usePatientValidationByStudyNumber(patient?.studyNumber!, false);

    const [formDefinitions, , ] = useFormDefinitions(false);

    const { form } = React.useContext(FormContext);

    const typedForm = form as Dtos.PatientStatusForm;

    React.useEffect(() => {

        let patientStatusCriteriaArr: Dtos.PatientStatusCriteria[] = [];

        patientValidation?.eventResults?.map(er => er.formResults)?.reduce((a, b) => a.concat(b))
            .filter(fr => fr.formDefinitionId !== Dtos.FormDefinitionType.PatientStatusForm)
            .forEach(fr => {
                if (fr?.errors?.filter(error => (error.type ?? 0) == Dtos.ValidationErrorType.Ineligible)?.length > 0) {

                    const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId);

                    fr?.errors?.filter(error => (error.type ?? 0) == Dtos.ValidationErrorType.Ineligible)?.forEach(error => {
                        let patientStatusCriteria: Dtos.PatientStatusCriteria = {} as Dtos.PatientStatusCriteria;

                        let fieldName = "";

                        if (!error.property.match(/\[\d\]\./g)) {
                            fieldName = formDefinition?.properties?.find(p => p.propertyName === error.property)?.label ?? "";
                        } else {
                            let subformPropertyName = error?.property.split(/\[\d\]\./g)[0];
                            let propertyName = error?.property.split(/\[\d\]\./g)[1];

                            fieldName = formDefinition?.subformProperties[subformPropertyName].find(p => p.propertyName === propertyName)?.label ?? "";
                        }

                        //patientStatusCriteria.waiverType = patient?.patientStateId === Dtos.PatientStateType.NewPatient ? Dtos.LookupWaiverType.Preregistration : Dtos.LookupWaiverType.Registration;
                        patientStatusCriteria.formName = (formDefinition?.name ?? "").replace(/<.*?>/g, "");
                        patientStatusCriteria.fieldName = fieldName.replace(/<.*?>/g, "");
                        patientStatusCriteria.details = error?.detailedMessage.replace(/<.*?>/g, "");

                        patientStatusCriteriaArr.push(patientStatusCriteria);
                    });
                }
            });

        setFieldValue(`patientStatusCriteria`, [...(typedForm.patientStatusCriteria ?? []), ...patientStatusCriteriaArr]);

        setFieldValue(`markAsIneligible`, true);
       
    }, [patientValidation, setFieldValue, formDefinitions, form, patient, typedForm]);

    return <FieldGroup>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
                Failed Eligibility Criteria
            </Typography>
            <FormGrid
                type={Dtos.PatientStatusCriteria}
                name="patientStatusCriteria"
                columns={PatientStatusCriteriaColumns}
                allowDelete={() => false}
                allowAdd={false}
            />
        </FieldGroup>
}

const PatientStatusForm: React.FunctionComponent<IPatientStatusFormProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    

    const params = useParams<IPatientStatusFormParams>();

    const [[canUpdateCrf, canAdministerOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { enqueueSnackbar } = useSnackbar();

    const allowSubmit = React.useCallback(async ({ errors }: IFormState<Dtos.PatientStatusForm, Dtos.IValidationError>, formActions: IFormActions<Dtos.PatientStatusForm, Dtos.IValidationError>) => {
        if (!errors) {
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => (e.type ?? Dtos.ValidationErrorType.Normal) >= Dtos.ValidationErrorType.Normal));
    }, []);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Dtos.PatientStatusForm, Dtos.IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: Dtos.IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                        if ((e.type ?? Dtos.ValidationErrorType.Normal) >= Dtos.ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const history = useHistory();

    const onFormCancel = React.useCallback(() => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
    }, [history, institution, patient]);

    const [patientValidation, patientValidationLoadState, ] = usePatientValidationByStudyNumber(patient?.studyNumber!, true);

    const [, formDefinitionsLoadState, ] = useFormDefinitions(true);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const afterSave = React.useCallback(async (institution?: Dtos.Institution | null, patient?: Dtos.Patient | null, event?: Dtos.IEvent | null, form?: Dtos.PatientStatusForm | null) => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}`);

        enqueueSnackbar(
            <>
                <AlertTitle>
                    Participant Marked Ineligible
                </AlertTitle>
                The participant was successfully marked as ineligible.
            </>,
            { variant: 'success' }
        );
    }, [enqueueSnackbar, onlinePatientManagement, history]);

    if (
        permissionLoadState.state === RequestState.None ||
        permissionLoadState.state === RequestState.Pending ||
        patientValidationLoadState.state === RequestState.None ||
        patientValidationLoadState.state === RequestState.Pending ||
        formDefinitionsLoadState.state === RequestState.None ||
        formDefinitionsLoadState.state === RequestState.Pending
    ) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
            <CrfForm
                formType={Dtos.PatientStatusForm}
                validateOn="onChange"
                canEdit={canUpdateCrf}
                allowSubmit={allowSubmit}
                onFormSubmitValidationFailure={onFormSubmitValidationFailure}
                afterFormSave={afterSave}
                afterFormSaveAndReturn={afterSave}
                hideSubmit
                hideSubmitAndReturn
                hideCancel
            >
                <PatientStatusFormFields />
                
                <Field
                    name="reason"
                    component={TextArea}
                    sm={12}
                    md={7}
                    xl={7}
                    lg={7}
                    
                />

                <Field
                    name="markAsIneligible"
                    sm={12}
                    md={7}
                    xl={7}
                    lg={7}
                    hidden
                />

                <div
                    className={classes.buttonGroup}
                >
                    {
                        patient?.patientStateId !== Dtos.PatientStateType.Ineligible && patient?.patientStateId !== Dtos.PatientStateType.Registered && canAdministerOpms && 
                        <SubmitButton
                            variant="contained"
                            color="primary"
                        >
                            Mark As Ineligible
                        </SubmitButton>
                    }
                    {
                        patient?.patientStateId !== Dtos.PatientStateType.Ineligible &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onFormCancel}
                        >
                            Cancel
                        </Button>
                    }
                </div>
                    
            </CrfForm>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientStatusForm;
