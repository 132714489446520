/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Checkbox,
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormGrid,
    GetFieldLookupItem,
    getParentPropertyPath,
    ICrfConditionParameters,
    IFormGridCell,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PageLayout,
    PatientContext,
    RouteLoading,
    Select,
    Text,
    ToggleButtonGroup,
    useFormState
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { makeStyles, Paper, Typography, } from '@material-ui/core';

import { get } from 'lodash-es';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { THREE_DIGIT_POSITIVE_INTEGER } from './General';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IImagingAndTumourAssessmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3, 3),
        fontSize: '1rem'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isCtScanNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.ctScanNotDone;
const isMriNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.mriNotDone;
const isMriBrainNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.mriBrainNotDone;
const isCtScanBrainNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.ctScanBrainNotDone;
const isChestXrayNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.chestXrayNotDone;
const isBoneScanNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.boneScanNotDone;
const isOtherAssessmentNotDoneFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => !formState?.values?.otherAssessmentNotDone;
const isBrainMetastasesEnabled = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => GetFieldLookupItem(lookups, 'mriBrainPositive', formState?.values.mriBrainPositive)?.type === Dtos.LookupYesNoType.Yes || GetFieldLookupItem(lookups, 'ctScanBrainPositive', formState?.values.ctScanBrainPositive)?.type === Dtos.LookupYesNoType.Yes;
const isBrainMetastasesUntreated = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => GetFieldLookupItem(lookups, 'brainMetastases', formState?.values.brainMetastases)?.type === Dtos.LookupBrainMetastasesType.Untreated;
const isBrainMetastasesTreated = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => GetFieldLookupItem(lookups, 'brainMetastases', formState?.values.brainMetastases)?.type === Dtos.LookupBrainMetastasesType.Treated;
const isLargestLesionGreaterThanTwo = ({ lookups, formState }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => GetFieldLookupItem(lookups, 'largeLesionSize', formState?.values.largeLesionSize)?.type === Dtos.LookupLargestLegionSizeType.GreaterThanTwo;

const isTargetLesionOrganSiteOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.TargetLesion = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.organSite === Dtos.LookupOrganSiteType.OtherOrgan as number;
}

const isNonTargetLesionOrganSiteOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.NonTargetLesion = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.organSite === Dtos.LookupOrganSiteType.OtherOrgan as number;
}

const isNonTargetLesionMeasurementOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ImagingAndTumourAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.NonTargetLesion = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.methodOfMeasurement === Dtos.LookupMethodOfMeasurementType.Other as number;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const targetLesionColumns: Array<IFormGridCell<Dtos.TargetLesion>> = [
    {
        name: 'targetLesionNumber',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Numeric}
            />
        )
    },
    {
        name: 'organSite',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'organSiteSpecify',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.ImagingAndTumourAssessment}
                condition={isTargetLesionOrganSiteOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'lesionLocation',
        minWidth: 20,
        maxWidth: 210,
        width: 210,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'methodOfMeasurement',
        minWidth: 190,
        width: 190,
        content: (
            <Input
                component={Select}
                style={{ width: '190px' }}
            />
        )
    },
    {
        name: 'measurement',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Numeric}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
            />
        )
    }
];

const nonTargetLesionColumns: Array<IFormGridCell<Dtos.NonTargetLesion>> = [
    {
        name: 'nonTargetLesionNumber',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Numeric}
            />
        )
    },
    {
        name: 'organSite',
        minWidth: 190,
        maxWidth: 190,
        width: 190,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'organSiteSpecify',
        minWidth: 180,
        maxWidth: 180,
        width: 180,
        content: (
            <CrfCondition
                type={Dtos.ImagingAndTumourAssessment}
                condition={isNonTargetLesionOrganSiteOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'lesionLocation',
        minWidth: 20,
        maxWidth: 190,
        width: 190,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'methodOfMeasurement',
        minWidth: 160,
        width: 160,
        content: (
            <Input
                component={Select}
                style={{ width: '160px' }}
            />
        )
    },
    {
        name: 'otherSpecify',
        minWidth: 170,
        maxWidth: 170,
        width: 170,
        content: (
            <CrfCondition
                type={Dtos.ImagingAndTumourAssessment}
                condition={isNonTargetLesionMeasurementOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    }
];


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const TargetLesionsTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.ImagingAndTumourAssessment, any>({ values: true });

    const sumOfTargetLesions = React.useMemo(() => {
        let sum = 0;

        formValues?.targetLesions?.forEach(tl => {
            sum += tl?.measurement ?? 0;
        });

        return sum;
    }, [formValues]);

    return (
        <>
            <Typography
                variant="h3"
                color="primary"
                className={classes.subHeading}
            >
                Target Lesions
            </Typography>
            <FormGrid
                type={Dtos.TargetLesion}
                name="targetLesions"
                columns={targetLesionColumns}
                onAdd={(values) => {
                    const gridSize = values?.length ?? 0;

                    let value = new Dtos.TargetLesion({ targetLesionNumber: gridSize + 1 });

                    if (values) {
                        return [...values, value];
                    }

                    return [value];

                }}
                onDelete={(values, index) => {
                    if (values === undefined) {
                        return;
                    }

                    if (index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values.forEach((value, index) => {
                        return value.targetLesionNumber = index + 1;
                    });

                    return values;
                }}
                minRow={1}
                rowLabel="Target Lesion"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].targetLesionNumber`);

                        return `Target Lesion ${rowValue}`;
                    }

                    return undefined;
                }}
            />
            <Field
                name="sumOfTargetLesions"
                component={Numeric}
                inputProps={{
                    value: sumOfTargetLesions,
                }}
                disabled
            />
        </>
    );
}

const NonTargetLesionsTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="primary"
                className={classes.subHeading}
            >
                Non-Target Lesions
            </Typography>
            <FormGrid
                type={Dtos.NonTargetLesion}
                name="nonTargetLesions"
                columns={nonTargetLesionColumns}
                onAdd={(values) => {
                    const gridSize = values?.length ?? 0;

                    let value = new Dtos.NonTargetLesion({ nonTargetLesionNumber: gridSize + 1 });

                    if (values) {
                        return [...values, value];
                    }

                    return [value];

                }}
                onDelete={(values, index) => {
                    if (!values || !values?.length || index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values?.forEach((value, index) => {
                        return value.nonTargetLesionNumber = index + 1;
                    });

                    return values;
                }}
                rowLabel="Non-target Lesion"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].nonTargetLesionNumber`);

                        return `Non-target Lesion ${rowValue}`;
                    }

                    return undefined;
                }}
            />
        </>
    );
}

const ImagingAndTumourAssessment: React.FunctionComponent<IImagingAndTumourAssessmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
            <CrfForm
                formType={Dtos.ImagingAndTumourAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Typography
                    variant="h6"
                    className={classes.instruction}
                >
                    <strong>Instructions:</strong> It is mandatory for a CT or MRI of the chest, abdomen and pelvis, and MRI of the brain to be performed at baseline within 28 days prior to registration. Participants with metastatic CNS tumours may participate in this study if they are clinically stable with respect to the CNS tumour at the time of screening as determined by clinical examination and brain imaging (MRI or CT).
                    <br /><br />
                    <strong>Record all measurable lesions in millimetres up to a maximum of 5 lesions (maximum of 2 lesions per organ).</strong>
                    <ul>
                        <li>Measurable tumour lesions are defined as those that can be accurately measured in at least one dimension (longest diameter to be recorded) as &ge; 20 mm with chest x-ray, and as &ge; 10 mm with CT scan or clinical examination. Clinical lesions will only be considered measurable when they are superficial and &ge; 10 mm as assessed using callipers (e.g. skin nodules). Bone lesions are considered measurable only if assessed by CT scan and have an identifiable soft tissue component that meets these requirements (soft tissue component &gt; 10 mm by CT scan). Malignant lymph nodes must be &ge; 15 mm in the short axis to be considered measurable; only the short axis will be measured and followed.</li>
                        <li>Previously irradiated lesions are not considered measurable unless progression has been documented in the lesion.</li>
                        <li>Target lesions should be selected on the basis of their size (lesions with the longest diameter), be representative of all involved organs, but in addition should be those that lend themselves to reproducible repeated measurements. Note that pathological lymph nodes must meet the criterion of having a short axis of &ge; 15 mm by CT scan and only the short axis of these lymph nodes will contribute to the baseline sum. All other pathological lymph nodes (those with a short axis &ge; 10 mm but &lt; 15 mm) should be considered non-target lesions. Nodes that have a short axis &lt; 10 mm are considered non-pathological and should not be recorded or followed.</li>
                    </ul>
                    All non-measurable lesions (or sites of disease) plus any measurable lesions over and above those listed as target lesions are considered non-target lesions.
                </Typography>
                <FieldGroup>
                    <Field
                        name="ctScanNotDone"
                        component={Checkbox}
                        label="CT Scan (Chest/Abdomen/Pelvis)"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isCtScanNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="ctScanDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="ctScanPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="mriNotDone"
                        component={Checkbox}
                        label="MRI (Chest/Abdomen/Pelvis)"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isMriNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="mriDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="mriPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="mriBrainNotDone"
                        component={Checkbox}
                        label="MRI (Brain)"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isMriBrainNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="mriBrainDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="mriBrainPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="ctScanBrainNotDone"
                        component={Checkbox}
                        label="CT Scan (Brain)"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isCtScanBrainNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="ctScanBrainDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="ctScanBrainPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                    
                </FieldGroup>
                <FieldGroup>
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isBrainMetastasesEnabled}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="brainMetastases"
                            component={Select}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isBrainMetastasesUntreated}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Untreated brain metastases
                        </Typography>
                        <Field
                            name="largeLesionSize"
                            component={ToggleButtonGroup}
                        />
                        <CrfCondition
                            type={Dtos.ImagingAndTumourAssessment}
                            condition={isLargestLesionGreaterThanTwo}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="hasApprovalGiven"
                                component={ToggleButtonGroup}
                            />
                        </CrfCondition>
                        <Field
                            name="localTherapyRequired"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isBrainMetastasesTreated}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Treated brain metastases
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.instruction}
                        >
                            <strong>Instruction: Relevant record of any CNS treatment must be de-identified and uploaded to allow for classification of target and non-target lesions.</strong>
                        </Typography>
                        <Field
                            name="stableSinceTreatment"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="timeSinceWholeBrainRt"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="timeSinceStereotacticRt"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="otherSitesAssessable"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="poorlyControlledSeizures"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="chestXrayNotDone"
                        component={Checkbox}
                        label="Chest X-Ray"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isChestXrayNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="chestXrayDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="chestXrayPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="boneScanNotDone"
                        component={Checkbox}
                        label="Bone Scan"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isBoneScanNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="boneScanDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="boneScanPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="otherAssessmentNotDone"
                        component={Checkbox}
                        label="Other Assessment"
                        paddingBottom={0}
                    />
                    <CrfCondition
                        type={Dtos.ImagingAndTumourAssessment}
                        condition={isOtherAssessmentNotDoneFalse}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="otherAssessmentSpecify"
                            component={Text}
                        />
                        <Field
                            name="otherAssessmentDate"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="otherAssessmentPositive"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                    
                </FieldGroup>

                <FieldGroup>
                    <TargetLesionsTbl />
                </FieldGroup>

                <FieldGroup>
                    <NonTargetLesionsTbl />
                </FieldGroup>

                <SourceDocuments />
            </CrfForm>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ImagingAndTumourAssessment;
