/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Checkbox,
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormLabel,
    GetFieldLookupItem,
    ICrfConditionParameters,
    IStaticGridCellProps,
    IStaticGridHeaderProps,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PageLayout,
    PatientContext,
    RouteLoading,
    Select,
    StaticGrid,
    StaticGridRow,
    ToggleButtonGroup
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { InputAdornment, Paper, Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { NumberFormatValues } from 'react-number-format';
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE } from './LaboratoryAssessments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IGeneralProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3, 3)
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------
 */

// const isBelow50 = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'age', formState?.values.age)?.type === Dtos.LookupAgeType.Below50;
const isSurgicalSterilisation = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'menopausalStatus', formState?.values.menopausalStatus)?.type === Dtos.LookupMenopausalStatusType.SurgicalSterilisation;
// const isPostMenopausal = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'menopausalStatus', formState?.values.menopausalStatus)?.type === Dtos.LookupMenopausalStatusType.PostMenopausal;
const isPreOrPeriMenopausal = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'menopausalStatus', formState?.values.menopausalStatus)?.type === Dtos.LookupMenopausalStatusType.PreOrPeriMenopausal;
const isNegativePregnantTest = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'preOrPeriMenopausalPregnancyTest', formState?.values.preOrPeriMenopausalPregnancyTest)?.type === Dtos.LookupYesNoType.Yes;
//const isLhLlnApplicable = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => !formState?.values.postmenopausalLevelsLhLlnNa;
//const isFshLlnApplicable = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => !formState?.values.postmenopausalLevelsFshLlnNa;
//const isOestradiolUlnApplicable = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => !formState?.values.postmenopausalLevelsOestradiolUlnNa;
const showHormonalLevels = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'age', formState?.values.age)?.type === Dtos.LookupAgeType.Below50 && (GetFieldLookupItem(lookups, 'menopausalStatus', formState?.values.menopausalStatus)?.type === Dtos.LookupMenopausalStatusType.PostMenopausal || (GetFieldLookupItem(lookups, 'menopausalStatus', formState?.values.menopausalStatus)?.type === Dtos.LookupMenopausalStatusType.SurgicalSterilisation && !formState?.values.bilateralOophorectomy));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const TWO_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

const postMenopausalTblHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'test',
        content: 'Test',
        width: '17.5%'
    },
    {
        columnName: 'value',
        content: 'Postmenopausal range',
        width: '27.5%'
    },
    {
        columnName: 'lln',
        content: 'LLN',
        width: '27.5%'
    },
    {
        columnName: 'uln',
        content: 'ULN',
        width: '27.5%'
    }
];

const postMenopausalLhRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel/>
        ),
        fieldName: 'postmenopausalLevelsLh'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
            />
        ),
        fieldName: 'postmenopausalLevelsLh'
    },
    {
        columnName: 'lln',
        content: (
            <>
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                U/L&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
            </>
        ),
        fieldName: 'postmenopausalLevelsLhLln'
    },
];

const postMenopausalFshRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'postmenopausalLevelsFsh'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
            />
        ),
        fieldName: 'postmenopausalLevelsFsh'
    },
    {
        columnName: 'lln',
        content: (
            <>
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                U/L&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
            </>
        ),
        fieldName: 'postmenopausalLevelsFshLln'
    }
];

const postMenopausalOestradiolRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'postmenopausalLevelsOestradiol'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            pmol/L&nbsp;
                        </InputAdornment>
                    )
                }}
            />
        ),
        fieldName: 'postmenopausalLevelsOestradiol'
    },
    {
        columnName: 'uln',
        content: (
            <>
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                pmol/L&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
            </>
        ),
        fieldName: 'postmenopausalLevelsOestradiolUln'
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const General: React.FunctionComponent<IGeneralProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
            <CrfForm
                formType={Dtos.General}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="physicalExaminationDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="height"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                cm&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="weight"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                kg&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE}
                />
                <Field
                    name="ecog"
                    component={Select}
                />
                <FieldGroup>
                    <Field
                        name="menopausalStatus"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.General}
                        condition={isSurgicalSterilisation}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="bilateralOophorectomy"
                            component={Checkbox}
                            label=""
                        />
                        <Field
                            name="bilateralSalpingectomy"
                            component={Checkbox}
                            label=""
                        />
                        <Field
                            name="hysterectomy"
                            component={Checkbox}
                            label=""
                        />
                    </CrfCondition>
                </FieldGroup>

                <CrfCondition
                    type={Dtos.General}
                    condition={showHormonalLevels}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <FieldGroup>
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Hormonal levels
                        </Typography>
                        <Field
                            name="postmenopausalTestDate"
                            component={KeyboardDatePicker}
                        />
                        <StaticGrid
                            headers={postMenopausalTblHeader}
                        >
                            <StaticGridRow
                                cells={postMenopausalLhRow}
                            />
                            <StaticGridRow
                                cells={postMenopausalFshRow}
                            />
                            <StaticGridRow
                                cells={postMenopausalOestradiolRow}
                            />
                        </StaticGrid>
                    </FieldGroup>
                </CrfCondition>
                <CrfCondition
                    type={Dtos.General}
                    condition={isPreOrPeriMenopausal}
                    mode="Show"
                    subscription={{ values: true }}
                > 
                    <FieldGroup>
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Pregnancy Test
                        </Typography>
                        <Field
                            name="preOrPeriMenopausalPregnancyTest"
                            component={ToggleButtonGroup}
                        />
                        <CrfCondition
                            type={Dtos.General}
                            condition={isNegativePregnantTest}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="preOrPeriMenopausalPregnancyTestDate"
                                component={KeyboardDatePicker}
                            />
                            <Field
                                name="preOrPeriMenopausalPregnancyTestResult"
                                component={ToggleButtonGroup}
                            />
                        </CrfCondition>
                    </FieldGroup>
                </CrfCondition>
                <Field
                    name="lvef"
                    component={Select}
                />
                <Field
                    name="lvefDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="lvefValue"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                %&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={TWO_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="ecgDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="ecgQctfValue"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                ms&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
                <SourceDocuments />
            </CrfForm>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default General;
