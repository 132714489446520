/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    CrfForm,
    Field,
    FormBreadcrumbs,
    InstitutionContext,
    MasterGroupContext,
    PageLayout,
    PatientContext,
    RouteLoading,
    ToggleButtonGroup
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, makeStyles } from '@material-ui/core';





import styled from 'styled-components';



/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IEligibilityChecklistProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    form: {
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(0deg) !important',
            background: 'inherit'
        },
        '& .MuiIconButton-root': {
            borderRadius: '0',
            width: '20%',
            textAlign: 'left',
            '& .MuiIconButton-label': {
                justifyContent: 'start'
            },
        },
        '& ol > li': {
            marginTop: '10px'
        }
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const Icon = styled(props => (
    <div {...props}>
        <div className="hide">&ndash; hide further details</div>
        <div className="expand">+ show further details</div>
    </div>
))`
    & > .expand {
        display: block;
        font-size: 0.8rem;
    }
    & > .hide {
        display: none;
        font-size: 0.8rem;
    }
    .Mui-expanded & > .hide {
        display: block;
        
    }
    .Mui-expanded & > .expand {
        display: none;
    }
`;

const ConfirmedHer2PositiveLabel: React.ReactNode = (
    <Accordion
        elevation={0}
        style={{ backgroundColor: 'inherit', boxShadow: '0' }}
    >
        <AccordionSummary
            style={{ paddingLeft: '0', marginTop: '-10px', marginBottom: '0', flexDirection: 'column', alignItems: 'flex-start' }}
            expandIcon={<Icon />}
        >
            <Typography>Local histologically confirmed HER2-positive unresectable loco-regional or metastatic breast cancer.</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                HER2-positive according to ASCO CAP 2018 guidelines:
                <ol type="a">
                    <li>ISH testing with ERBB2-amplification as demonstrated by ratio ERBB2/centromeres &ge; 2.0 or mean gene <br />copy number &ge; 6 <br /><div style={{ marginTop: '10px' }}>OR</div></li>
                    <li>3+ staining by IHC.</li>
                </ol>
            </Typography>
        </AccordionDetails>
    </Accordion>
);

const PreviouslyTreatedWithLapatinibLabel: React.ReactNode = (
    <>
        <Typography>Previously treated with:
            <ol type="a">
                <li>Lapatinib within 12 months of registration <br /><div style={{ marginTop: '10px', fontWeight: 600 }}>OR</div></li>
                <li>Neratinib or afatinib within 12 months of registration, unless ceased due to toxicity and not progression. </li>
            </ol>
        </Typography>
    </>
);

const HistoryOfCardiacDiseaseLabel: React.ReactNode = (
    <Accordion
        elevation={0}
        style={{ backgroundColor: 'inherit', boxShadow: '0' }}
    >
        <AccordionSummary
            style={{ paddingLeft: '0', marginTop: '-10px', marginBottom: '0', flexDirection: 'column', alignItems: 'flex-start' }}
            expandIcon={<Icon />}
        >
            <Typography>History of clinically significant or uncontrolled cardiac disease</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                Including congestive heart failure (New York Heart Association functional classification &ge; 3), angina, myocardial infarction or ventricular arrhythmia. Have known myocardial infarction or unstable angina within 6 months before registration.
            </Typography>
        </AccordionDetails>
    </Accordion>
);

const ActiveOrHistoryOfAutoimmuneDiseaseLabel: React.ReactNode = (
    <Accordion
        elevation={0}
        style={{ backgroundColor: 'inherit', boxShadow: '0' }}
    >
        <AccordionSummary
            style={{ paddingLeft: '0', marginTop: '-10px', marginBottom: '0', flexDirection: 'column', alignItems: 'flex-start' }}
            expandIcon={<Icon />}
        >
            <Typography>Active or history of autoimmune disease or immune deficiency </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                Including but not limited to:
                <br /><br />
                myasthenia gravis, myositis, autoimmune hepatitis, systemic lupus erythematosus, rheumatoid arthritis, inflammatory bowel disease, antiphospholipid antibody syndrome, Wegener granulomatosis, Sj&ouml;grens syndrome, Guillain-Barr&eacute; syndrome, or multiple sclerosis, with the following exceptions:
                <ol type="a">
                    <li>History of autoimmune-related hypothyroidism who are on thyroid-replacement hormone are eligible</li>
                    <li>Stable diabetes mellitus are eligible</li>
                    <li>
                        Eczema, psoriasis, lichen simplex chronicus, or vitiligo with dermatologic manifestations only (e.g. patients with psoriatic arthritis are excluded) are eligible provided ALL the following conditions are met:
                        <ol type="i">
                            <li>Rash must cover &lt; 10% of body surface area</li>
                            <li>Disease is well controlled at baseline and requires only low-potency topical corticosteroids</li>
                            <li>No occurrence of acute exacerbations of the underlying condition requiring psoralen plus ultraviolet A radiation, methotrexate, retinoids, biologic agents, oral calcineurin inhibitors, or high-potency or oral corticosteroids within the previous 12 months. </li>
                        </ol>
                    </li>
                </ol>
            </Typography>
        </AccordionDetails>
    </Accordion>
);

const KnownHivOrActiveHepatitisLabel: React.ReactNode = (
    <Accordion
        elevation={0}
        style={{ backgroundColor: 'inherit', boxShadow: '0' }}
    >
        <AccordionSummary
            style={{ paddingLeft: '0', marginTop: '-10px', marginBottom: '0', flexDirection: 'column', alignItems: 'flex-start' }}
            expandIcon={<Icon />}
        >
            <Typography>Known human immunodeficiency virus (HIV) (HIV1/2antibodies) or active Hepatitis B (HBsAg reactive) or Hepatitis C (HCV RNA [qualitative]).</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                <ol type="a">
                    <li>Participants with past hepatitis B virus (HBV) infection or resolved HBV infection (defined as having a negative HBsAg test and a positive antibody to hepatitis B core antigen antibody test) are eligible</li>
                    <li>Participants positive for HCV antibody are eligible only if polymerase change reaction is negative for HCV RNA.</li>
                </ol>
            </Typography>
        </AccordionDetails>
    </Accordion>
);

const EligibilityChecklist: React.FunctionComponent<IEligibilityChecklistProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
                <div className={classes.form}>
                    <CrfForm
                        formType={Dtos.EligibilityChecklist}
                        validateOn="onChange"
                        canEdit={canUpdateCrf}
                    >
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Pre-registration Inclusion Criteria &ndash; Must be YES or patient ineligible
                        </Typography>
                        <Field
                            name="confirmedHer2Positive"
                            component={ToggleButtonGroup}
                            label={ConfirmedHer2PositiveLabel}
                        />
                        <Field
                            name="previouslyReceivedTaxane"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="progressionOfBreastCancer"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="lifeExpectancyAtleast6Months"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="agreeOnContraception"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="willingToComplyProtocol"
                            component={ToggleButtonGroup}
                        />
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Pre-registration Exclusion Criteria &ndash; Must be NO or patient ineligible
                        </Typography>
                        <Field
                            name="previouslyTreatedWithLapatinib"
                            component={ToggleButtonGroup}
                            label={PreviouslyTreatedWithLapatinibLabel}
                        />
                        <Field
                            name="priorAntiPd1"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="previousSevereHypersensitivity"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="knownOrSuspectedLeptomeningeal"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="havePoorlyControlledSeizures"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="historyOfCardiacDisease"
                            component={ToggleButtonGroup}
                            label={HistoryOfCardiacDiseaseLabel}
                        />
                        <Field
                            name="activeOrHistoryOfAutoimmuneDisease"
                            component={ToggleButtonGroup}
                            label={ActiveOrHistoryOfAutoimmuneDiseaseLabel}
                        />
                        <Field
                            name="knownHivOrActiveHepatitis"
                            component={ToggleButtonGroup}
                            label={KnownHivOrActiveHepatitisLabel}
                        />
                        <Field
                            name="stoppedBreastFeeding"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="requireTherapyWithWarfarin"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="significantGastrointestinalDisease"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="historyOrActiveLungDisease"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="activeTuberculosis"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="hadTissueOrganTransplant"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="uncontrolledHypertension"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="anyConditionConfoundingResults"
                            component={ToggleButtonGroup}
                        />
                        <SourceDocuments />
                    </CrfForm>
                </div>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default EligibilityChecklist;
