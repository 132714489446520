/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use screening log columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

import { makeStyles, Theme } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';

import { PatientsContext } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';
import { getGenderValue } from './useScreenFailsColumns';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const getStatusValue = (status: number | undefined) => {
    switch (status) {
        case Dtos.PatientStateType.NewPatient: { return 'New'; }
        case Dtos.PatientStateType.Preregistered: { return 'Preregistered'; }
        case Dtos.PatientStateType.Registered: { return 'Registered'; }
        case Dtos.PatientStateType.Ineligible: { return 'Ineligible'; }
        default: { return ''; }
    }
}

const useScreenedParticipantsColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean, showStudyNumber: boolean): Array<Column<Dtos.ScreeningLog>> => {
    const classes = useStyles();

    const { patients } = React.useContext(PatientsContext);

    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.ScreeningLog> | boolean> = [
            {
                field: 'screeningDate',
                title: 'Screening Date',
                render: (screeningLog, type) => (screeningLog.screeningDate ? DateTime.fromISO(screeningLog.screeningDate).toFormat('dd/MM/yyyy') : ''),
                width: '15%'
            },
            showSite && {
                field: 'institutionId',
                title: 'Site',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.id === (screeningLog.institutionId))?.name}</div>),
                customSort: (data1, data2, type) => !!institutions && ((institutions.find(i => i.id === (data1.institutionId)) as Dtos.IInstitution).name) < ((institutions.find(i => i.id === (data2.institutionId)) as Dtos.IInstitution).name) ? -1 : 1,
                width: '20%'
            },
            {
                field: 'initials',
                title: 'Initials',
                render: (screeningLog, type) => (screeningLog.initials),
                width: '10%'
            },
            {
                field: 'dateOfBirth',
                title: 'Date of Birth',
                render: (screeningLog, type) => (screeningLog.dateOfBirth ? DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy') : ''),
                width: '10%'
            },
            {
                field: 'gender',
                title: 'Gender',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{getGenderValue(screeningLog.gender)}</div>),
                width: '10%'
            },
            showStudyNumber && {
                field: 'patientId',
                title: 'Study Number',
                render: (screeningLog, type) => ((patients?.find(p => p.id === screeningLog.patientId) as Dtos.Patient)?.studyNumber),
                customSort: (data1, data2, type) => !!patients && ((patients?.find(p => p.id === data1.patientId) as Dtos.Patient)?.studyNumber ?? '') < ((patients?.find(p => p.id === data2.patientId) as Dtos.Patient)?.studyNumber ?? '') ? -1 : 1,
                width: '10%'
            },
        ];

        return cols.filter(column => !!column) as Array<Column<Dtos.ScreeningLog>>;
    }, [patients, institutions, showSite, classes, showStudyNumber]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScreenedParticipantsColumns;
