import { makeStyles } from '@material-ui/core';
import IframeResizer from 'iframe-resizer-react';
import * as React from 'react';
import { RouteLoading } from '@ngt/opms';

interface IIFramePageProps {
    url: string;
}

const useStyles = makeStyles(theme => ({
    iframe: {
        width: '100%',
        border: 'none',
        minWidth: '100%'
    }
}));

const IFramePage: React.FunctionComponent<IIFramePageProps> = ({ url }) => {
    const classes = useStyles();

    const [loading, setLoading] = React.useState(true);

    return (
        <>

            {
                loading && <RouteLoading />
            }

            <IframeResizer
                height={0}  
                heightCalculationMethod="lowestElement"
                inPageLinks={false}
                log
                src={url}
                className={classes.iframe}
                onLoad={() => setLoading(false)}
            />
        </>
    );
};

export default IFramePage;
