/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    FormLookupContext,
    useFieldState,
    useFormActions,
    getParentPropertyPath
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IInputDisplayProps {
    convert?: (value: any, lookups: Dtos.ILookup[]) => any;
    specifyName?: string;
    specifyConvert?: (value: any, lookups: Dtos.ILookup[]) => any;
}

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const InputDisplay: React.FunctionComponent<IInputDisplayProps> = ({
    convert,
    specifyName,
    specifyConvert
}) => {
    const lookups = React.useContext(FormLookupContext);

    const { value, name } = useFieldState({ value: true });

    const { getFieldValue, } = useFormActions();

    const parentName = getParentPropertyPath(name) ?? '';

    const specifyValue = specifyName ? getFieldValue(`${parentName}.${specifyName}`) : undefined;


    return (
        <>
            {convert ? convert(value, lookups ? [lookups] : []) : value}
            {specifyConvert ? specifyConvert(specifyValue, lookups ? [lookups] : []) : undefined}
        </>
    );

}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default InputDisplay;
