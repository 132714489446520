/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to pass event context to the rest of the app.
 */
import {
    useEventByCodes,
    useFormDefinitionByCode,
    useFormByCodes,
    useLookupsByFormDefinitionId,
    useEventDefinitionByCode,
    EventDefinitionContext,
    FormDefinitionContext,
    EventContext,
    FormContext,
    LookupsContext,
    RouteLoading,

    InstitutionContext,
    PatientContext
} from '@ngt/opms';


/*
 * Used to show loading view.
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ICrfFormMappingProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    mode: "save" | "remove";
    loading: boolean;
}

export interface ICrfFormMapping {
    component: (props: ICrfFormMappingProps) => React.ReactNode;
}

export interface IDialogFormResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStudyNumber?: string | null;
    eventDefinitionCode?: string | null;
    eventRepeat?: number | null;
    formDefinitionCode?: string | null;
    formRepeat?: number | null;
    createPatient?: boolean | null;
    //formMapping?: Record<string, ICrfFormMapping>;
    resolveBeforeLoad?: boolean | null;
    createForm?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const DialogFormResolver: React.FunctionComponent<IDialogFormResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStudyNumber,
    eventDefinitionCode,
    eventRepeat,
    formDefinitionCode,
    formRepeat,
    createPatient,
    resolveBeforeLoad,
    //formMapping,
    children,
}) => {

    if (!createPatient && !patientStudyNumber) {
        throw new Error('Route Invalid: Patient creation not enabled and no study number was provided.');
    }

    if (!patientStudyNumber && !institutionCode) {
        throw new Error('Route Invalid: No institution code was provided (required for new patient creation).');
    }

    if (!eventDefinitionCode) {
        throw new Error('Route Invalid: No event definition code has been provided.');
    }

    if (!eventRepeat) {
        throw new Error('Route Invalid: No event repeat has been provided.')
    }

    if (!formDefinitionCode) {
        throw new Error('Route Invalid: No form definition code has been provided.');
    }

    // load event definition
    const [eventDefinition, eventDefinitionLoadState, eventDefinitionActions] = useEventDefinitionByCode(eventDefinitionCode, true);

    const eventDefinitionContext = React.useMemo(() => {
        return {
            eventDefinition,
            loadState: eventDefinitionLoadState,
            actions: eventDefinitionActions
        };
    }, [eventDefinition, eventDefinitionLoadState, eventDefinitionActions]);

    // load form definition
    const [formDefinition, formDefinitionLoadState, formDefinitionActions] = useFormDefinitionByCode(formDefinitionCode, true);

    const formDefinitionContext = React.useMemo(() => {
        return {
            formDefinition,
            loadState: formDefinitionLoadState,
            actions: formDefinitionActions
        };
    }, [formDefinition, formDefinitionLoadState, formDefinitionActions]);

    // load event
    const institutionContext = React.useContext(InstitutionContext);
    const patientContext = React.useContext(PatientContext);

    const createEvent = true;

    const loadEvent = !!institutionContext.institution?.code && !!patientContext.patient?.studyNumber;

    const [event, eventLoadState, eventSaveState, eventActions] = useEventByCodes(institutionContext.institution?.code, patientContext.patient?.studyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent, loadEvent);

    const eventContext = React.useMemo(() => {
        return {
            event,
            loadState: eventLoadState,
            saveState: eventSaveState,
            actions: eventActions
        };
    }, [event, eventLoadState, eventActions, eventSaveState]);

    // load form

    const createForm = true;

    const loadForm = !!formRepeat;

    const [form, formLoadState, formSaveState, formActions] = useFormByCodes(formDefinitionCode, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm, loadForm);

    const formContext = React.useMemo(() => {
        return {
            form,
            loadState: formLoadState,
            saveState: formSaveState,
            actions: formActions
        };
    }, [form, formLoadState, formActions, formSaveState]);

    // get lookups for form.
    const loadLookups = !!formDefinition?.id;

    const [lookups, lookupsLoadState, lookupsActions] = useLookupsByFormDefinitionId(formDefinition?.id ?? 0, loadLookups);

    const lookupsContext = React.useMemo(() => {
        return {
            lookups,
            loadState: lookupsLoadState,
            actions: lookupsActions
        };
    }, [lookups, lookupsLoadState, lookupsActions]);


    const notLoading =
        eventDefinitionContext.loadState.state !== RequestState.None &&
        eventDefinitionContext.loadState.state !== RequestState.Pending &&
        formDefinitionContext.loadState.state !== RequestState.None &&
        formDefinitionContext.loadState.state !== RequestState.Pending &&
        (loadEvent === false ||
            (eventContext.loadState.state !== RequestState.None &&
            eventContext.loadState.state !== RequestState.Pending)) &&
        (loadForm === false || 
            (formContext.loadState.state !== RequestState.None &&
            formContext.loadState.state !== RequestState.Pending)) &&
        lookupsContext.loadState.state !== RequestState.None &&
        lookupsContext.loadState.state !== RequestState.Pending;

    //const Component = formMapping?.[formDefinitionCode]?.component as any;

    //let conditionalContexts: React.ReactNode = React.useMemo(() => {
    //    return Component ? <Component open={open} setOpen={setOpen} mode={mode} loading={resolveBeforeLoad && !notLoading} /> : children;
    //}, [Component, resolveBeforeLoad, notLoading, mode, open, setOpen, children])

    if (!notLoading) {
        return <RouteLoading />
    }

    return (
        <EventDefinitionContext.Provider value={eventDefinitionContext}>
            <FormDefinitionContext.Provider value={formDefinitionContext}>
                <EventContext.Provider value={eventContext}>
                    <FormContext.Provider value={formContext}>
                        <LookupsContext.Provider value={lookupsContext}>
                            {
                                children
                            }
                        </LookupsContext.Provider>
                    </FormContext.Provider>
                </EventContext.Provider>
            </FormDefinitionContext.Provider>
        </EventDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default DialogFormResolver;