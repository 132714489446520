/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the invitation page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';

import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    AppBar,
    Button,
    CardMedia,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Theme,
    Tooltip,
    Typography,
    makeStyles,
    styled
} from '@material-ui/core';
import { OnlinePatientManagementContext, useIsMobile, useSnackbar } from '@ngt/opms';
import { useForm } from "react-hook-form";

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import AlertTitle from '@material-ui/lab/AlertTitle';
import { DateTime } from 'luxon';
import * as Dtos from '../../api/dtos';
import { StatusTypeEnum } from '../../api/dtos';
import her2Image from '../../assets/img/her2-inhibitors.jpg';
import trialPlanImage from '../../assets/img/trial-plan.jpg';
import trialProcedureImage from '../../assets/img/trial-procedure.jpg';
import trialTimeLineImage from '../../assets/img/trial-timeline.jpg';
import trialTreatmentImage from '../../assets/img/trial-treatment.jpg';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvitationPageProps {
}

interface IInvitationPageParams {
    guid: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '1rem'
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    eoiTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.75rem',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    p: {
        marginBottom: '1rem'
    },
    cardMedia: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%'
    },
    cardMediaContainer: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative'
    },
    quickAccess: {
        display: 'flex',
        backgroundColor: 'white'
    },
    quickAccessHeader: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: '1rem',
        fontSize: '1.25rem'
    },
    quickAccessBody: {
        display: 'flex',
        backgroundColor: 'white',
        color: theme.palette.secondary.main,
        fontSize: '1.0rem'
    },
    quickAccessSummary: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '1.25rem'
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    accordions: {
        marginTop: '1rem'
    },
    accordionSummary: {
        color: theme.palette.primary.main,
        fontSize: '2rem'
    },
    listImage: {
        width: '100%',
        height: 'auto'
    },
    eoiGridItem: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'center'

    },
    eoiGrid: {
        '& .MuiGrid-item': {
            padding: theme.spacing(0, 2)
        }
    },
    eoiSubmit: {
        borderRadius: 15,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    error: {
        color: '#f44336'
    },
    submitSuccess: {
        backgroundColor: "#ece7f1",
        color: theme.palette.primary.main,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(5),
    },
    submitSuccessMessage: {
        fontSize: '2rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    submitSuccessIcon: {
        color: theme.palette.secondary.main,
        fontSize: '4rem'
    },
    errorMessage: {
        color: 'red',
        padding: '0.15rem',
        fontSize: '0.85rem'
    },
    listItem: {
        alignItems: 'flex-start'
    },
    listItemIcon: {
        marginTop: '10px'
    },
    flexBasis: {
        flexBasis: '69%',
        [theme.breakpoints.down('xs')]: {
            flexBasis: '100%',
        }
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

//const PicfSummaryKeyWord = 'Summary';


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const CustomAccordion = styled((props: AccordionProps) => (
    <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    }
}));

const CustomToolTip: React.FunctionComponent<{
    label?: string,
    title?: string
}> = ({
    label,
    title
}) => {
    const tip: React.ReactElement = (
        <span style={{ cursor: "help", textDecoration: "underline", textDecorationStyle: "dotted" }}>
            {label}
            <sup style={{ fontSize: 10 }}>&#9432;</sup>
        </span >
    );

    return (
        <Tooltip title={title!} children={tip}/>
    );
}

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronRight} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '&  .MuiAccordionSummary-expandIcon': {
        color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem'
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const EoiField: React.FunctionComponent<{
    label?: string,
    inputComponent: React.ReactNode
}> = ({
    label,
    inputComponent
}) => {
    const classes = useStyles();

    return (
        <>
            {
                !!label && <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    xl={2}
                    className={classes.eoiGridItem}
                >
                    {label}
                </Grid>
            }
            <Grid
                item
                xs={12}
                sm={12}
                md={!!label ? 8 : 12}
                lg={!!label ? 10 : 12}
                xl={!!label ? 10 : 12}
                className={classes.eoiGridItem}
            >
                {inputComponent}
            </Grid>
        </>    
    );
    }

const ErrorMessage: React.FunctionComponent<{ message: string }> = ({ message }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span style={{ marginLeft: "0.5rem" }}>{message}</span>
        </div>
    );
}

const EoiForm: React.FunctionComponent<{
    setEoiSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
    setEoiSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const params = useParams<IInvitationPageParams>();

    const invitationGuid = params?.guid;

    const onSubmit = React.useCallback((data) => {

        const eoi: Dtos.Eoi = new Dtos.Eoi({
            firstName: data.firstName,
            lastName: data.lastName,
            confirmed: data.confirmed,
            dateSubmitted: DateTime.local().toUTC().toString(),
            statusId: StatusTypeEnum.Waiting
        });

        client
            .post(new Dtos.EoiPostSave({
                eoi: eoi,
                invitationGuid: invitationGuid
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Submitted
                        </AlertTitle>
                        Your expression of interest was submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                setEoiSubmitted(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Not Submitted
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [client, enqueueSnackbar, invitationGuid, setEoiSubmitted]);

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<Dtos.Eoi>();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                    className={classes.eoiGrid}
                >
                    <EoiField
                        label={"First Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="First name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("firstName", { required: true })}
                                />
                                {errors.firstName && <ErrorMessage message={"This field is required"}/>}
                            </>
                        }
                    />
                    <EoiField
                        label={"Last Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="Last name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("lastName", { required: true })}
                                />
                                {errors.lastName && <ErrorMessage message={"This field is required"} />}
                            </>
                        }
                    />
                    <EoiField
                        inputComponent={
                            <>
                                <FormGroup style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
                                    <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register("confirmed", { required: true })}
                                            />
                                        }
                                        label="I confirm that I have read and understood the information on this page"
                                    />
                                        {errors.confirmed && <ErrorMessage message={"This field is required"} />}
                                    </div>
                                </FormGroup>
                            </>
                        }
                    />
                </Grid>
                
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.eoiSubmit}
                    disabled={isSubmitting}
                    fullWidth
                >
                    Submit
                </Button>
            </form>
        </>    
    );
}

const InvitationPage: React.FunctionComponent<IInvitationPageProps> = () => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    const params = useParams<IInvitationPageParams>();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : '');
    };

    const invitationGuid = params?.guid;

    const [invitation, setInvitation] = React.useState<Dtos.Invitation | undefined>();

    const [picfs, setPicfs] = React.useState<Dtos.Picf[] | undefined>();

    const picf = React.useMemo(() => {
        return picfs?.find(p => p.type.name === "Main");
    }, [picfs]);

    const [eoiSubmitted, setEoiSubmitted] = React.useState(false);

    // const picfSummaryDownloadLink = `/opms/invitation/download/picf/${invitationGuid}/${PicfSummaryKeyWord}`;

    // const onPicfSummaryDownloadClick = React.useCallback(() => {
    //     client
    //        .get(new Dtos.InvitationGetPicf({
    //            guid: invitationGuid,
    //            name: PicfSummaryKeyWord
    //        }))
    //        .then(response => {
    //            var link = document.createElement('a');
    //            link.href = picfSummaryDownloadLink;
    //            link.click();
    //            link.remove();
    //        })
    //        .catch((e) => {
    //            enqueueSnackbar(
    //                <>
    //                    <AlertTitle>
    //                        Error
    //                    </AlertTitle>
    //                    An error occurred while trying to download the PICF file. Please contact your system administrator.
    //                </>,
    //                { variant: 'critical' }
    //            );
    //        })
    // }, []);

    React.useEffect(() => {
        client
            .get(new Dtos.InvitationGetSingleByGuid({
                guid: invitationGuid
            }))
            .then(response => {
                setInvitation(response.invitation)
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        Invitation is not valid. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [client, enqueueSnackbar, invitationGuid, setInvitation]);


    React.useEffect(() => {
        if (invitation) {
            client
                .get(new Dtos.GetPicfs({
                    instCode: invitation?.institutionCode
                }))
                .then(response => {
                    setPicfs(response.picfs)
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            There is a problem with the site PICFs. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, invitation, setPicfs]);

    return (
        <>
            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    className={classes.title}
                >
                    <strong>The TUGETHER <Link href="https://www.breastcancertrials.org.au/trials/tugether/" color="secondary">Trial</Link></strong> <br />
                    BCT 2102 TUGETHER
                </Typography>
                <Grid
                    container
                    spacing={5}
                >
                    {
                        !!isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ paddingBottom: 0 }}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                                        aria-controls="panel0a-content"
                                        id="panel0a-header"
                                        className={classes.quickAccessSummary}
                                    >
                                        <Typography>Quick Access</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <ListItem component={Link} href={"#TugetherVideo"} className={classes.link}>
                                                <ListItemText primary="TUGETHER Information Video" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherPurpose"} className={classes.link}>
                                                <ListItemText primary="What is the Purpose of TUGETHER?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherToDo"} className={classes.link}>
                                                <ListItemText primary="What Do I Need to Do First?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherInvolve"} className={classes.link}>
                                                <ListItemText primary="What Does Taking Part in TUGETHER Involve?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherPlan"} className={classes.link}>
                                                <ListItemText primary="TUGETHER Trial Plan" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherTreatment"} className={classes.link}>
                                                <ListItemText primary="TUGETHER Trial Treatment" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherProcedures"} className={classes.link}>
                                                <ListItemText primary="TUGETHER Trial Procedures" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherTimeline"} className={classes.link}>
                                                <ListItemText primary="TUGETHER Timeline" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherNextSteps"} className={classes.link}>
                                                <ListItemText primary="Next Steps" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherEoi"} className={classes.link}>
                                                <ListItemText primary="Expression of Interest" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#TugetherResources"} className={classes.link}>
                                                <ListItemText primary="Resources" />
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </AppBar>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={12}
                        md={8}
                    >
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            This page is a summary of the TUGETHER clinical trial and what happens if you decide to take part. Take as much time as you need to decide about taking part. Talk to your family, friends, and/or family doctor about taking part. Feel free to ask any questions of the Study Doctor and site staff at any time.</Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            TUGETHER is a clinical trial looking at treating <CustomToolTip title="Advanced breast cancer - cancer that has spread from the original site in the breast (metastasised) to other organs or tissues in the body. Also known as secondary breast cancer or metastatic breast cancer." label="advanced" /> <CustomToolTip title="HER2-positive – Human Epidermal Receptor 2 positive. HER2 is a receptor which is known to promote cancer growth when present in higher levels than normal." label="HER2-positive" /> breast cancer that has come back or spread after anti-HER2 treatment, such as trastuzumab (Herceptin®), pertuzumab (Perjeta®) or TDM-1 (Kadcycla®).</Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            To understand more about the TUGETHER trial and what is involved in taking part, click on the headings below to expand the information. There is also further information on the <Link href={"#TugetherResources"} className={classes.link}>Resources</Link> section of this page
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="TugetherVideo"
                        >
                            TUGETHER Information Video
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            You may like to take notes of any questions or concerns you have while watching the video or reading this page to ask your study doctor and study team.
                        </Typography>
                        <div className={classes.cardMediaContainer}>
                            <CardMedia
                                component="iframe"
                                src="https://www.youtube.com/embed/g4BduF3gtu4"
                                className={classes.cardMedia}
                            />
                        </div>
                        <div className={classes.accordions}>
                            <CustomAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} id="TugetherPurpose">
                                <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.accordionSummary}>
                                    <Typography>What is the Purpose of TUGETHER?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary="The purpose of this research is to find out if cancer will take longer to get worse in patients with HER2-positive advanced breast cancer who are treated with a drug called tucatinib (Tukysa®) and pembrolizumab (Keytruda®)." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography><CustomToolTip title="Tukysa®" label="Tucatinib" /> is a type of drug called a <CustomToolTip title="Tyrosine kinase is an enzyme important for communication within cells and can act as an “on/off” switch, affecting cell growth. &#13; An inhibitor generally slows down or stops a particular process.&#10;Tyrosine kinases can become stuck in the “on” position, allowing cancer cells to grow. A tyrosine kinase inhibitor can turn the switch “off”, stopping or slowing cancer cells from growing." label="tyrosine kinase inhibitor" /> .It targets a particular part of the HER2 <CustomToolTip title="Receptors help control cell growth and repair." label="receptor" /> on cancer cells in a different way to other drugs that are used to treat HER2 positive cancer, such as <CustomToolTip title="Herceptin®" label="trastuzumab" />.</Typography>} />
                                        </ListItem>
                                        <div className={classes.accordions} style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px' }}>
                                            <Accordion id="HER2" defaultExpanded>
                                                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" expandIcon={<FontAwesomeIcon icon={faChevronDown} />} className={classes.accordionSummary}>
                                                    <Typography>Inhibitors of HER2 receptors </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <img
                                                        className={classes.listImage}
                                                        src={her2Image}
                                                        alt={"HER2 Inhibitors"}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography>We also want to find out if adding a drug called pembrolizumab (Keytruda®) to the treatment will increase the effectiveness of <CustomToolTip title="Tukysa®" label="tucatinib" /> and <CustomToolTip title="Herceptin®" label="trastuzumab"/> by helping the body’s immune system to fight the cancer.</Typography>} />
                                        </ListItem>
                                        
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography><CustomToolTip title="Keytruda®" label="Pembrolizumab" /> is type of <CustomToolTip title="Antibody: used by the immune system to identify and stop foreign objects in the body." label="antibody" /> that works against a protein called <CustomToolTip title="PD-L1: Programmed death ligand 1. May play a major role in preventing the immune system from responding to foreign cells, such as cancer. Cancer cells may also produce PD-L1 to hide from the body’s immune system." label="PD-L1" /> which is found on the surface of cancer cells.It is thought that cancer cells with increased levels of PD-L1 (‘PD-L1 Positive’) hide from the body’s immune system. Pembrolizumab  may help the body’s immune system to fight the cancer. TUGETHER aims to find out how well <CustomToolTip title="Tukysa®" label="tucatinib"/> and <CustomToolTip title="Keytruda®" label="pembrolizumab"/> work together.</Typography>} />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography>Participants with a low level of the PD-L1 protein (‘PD-L1 Negative/Unknown’) will also receive standard treatment with capecitabine (Xeloda®). This will make sure that patients receive the best possible treatment, as researchers believe that additional treatment may be needed for <CustomToolTip title="Keytruda®" label="pembrolizumab" /> to work in patients with low levels of PD-L1 (PD-L1 Negative/Unknown).</Typography>}/>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography><CustomToolTip title="Keytruda®" label="Pembrolizumab" /> is expected to work in PD-L1 positive cancers and so researchers want to find out if a new treatment program without <CustomToolTip title="Xeloda®" label="capecitabine" /> can be used for HER2-positive, advanced breast cancer that avoids the side effects caused by <CustomToolTip title="Xeloda®" label="capecitabine" /> , while still being effective in treating the cancer.</Typography>} />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} id="TugetherToDo">
                                <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header" className={classes.accordionSummary}>
                                    <Typography>What Do I Need to Do First?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={<Typography>To take part in the TUGETHER clinical trial, you will need to talk with a study doctor about the study, have all your questions answered, and sign the <CustomToolTip title=" Consent is a process where a person agrees to take part based on a clear understanding of the facts, the risks and benefits, and what it might mean for them in the future. In the case of a clinical trial, these facts, risks, benefits and future impacts are set out in the Participant Information Sheet and any related materials, such as the information on this webpage." label="Consent" />  section of the full Participant Information Sheet/Consent Form.</Typography>} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="If you don’t wish to take part in this study, you don’t have to. You will receive the best possible care whether or not you take part. If you decide to take part, you can stop at any time if you change your mind later." />
                                        </ListItem>
                                        <ListItem>
                                        <ListItemText>
                                                All trials must be registered on a publicly accessible database. Further information on TUGETHER can also be found on the <Link href="https://clinicaltrials.gov/ct2/show/NCT04789096" target="_blank" rel="noopener" className={classes.link}>public registry</Link>.
                                        </ListItemText>
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} id="TugetherInvolve">
                                <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header" className={classes.accordionSummary}>
                                    <Typography>What Does Taking Part in TUGETHER Involve?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary="If you decide to take part in TUGETHER you will be asked to sign a Consent Form after reviewing the study information, and before starting any study procedures." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary="If you decide to take part, you will be in one of two possible treatment groups. The group you are in will be based on tests on a tumour sample before you start treatment that will find out if the tumour has high or low levels of a protein called PD-L1 (PD-L1 Positive or PD-L1 Negative/Unknown)." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1.3rem' }}>
                                                    <div><u>Group 1 (PD-L1 positive):</u></div> 
                                                    <div className={classes.flexBasis}>You will take a tucatinib tablet twice per day with water for 21 days, plus have an infusion of pembrolizumab and trastuzumab into your vein on day 1 of each 21 day cycle.</div>
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                                                    <div><u>Group 2 (PD-L1 Negative/Unknown):</u></div>
                                                    <div className={classes.flexBasis}>You will take a tucatinib tablet twice per day with water for 21 days, capecitabine tablets twice per day after food for 14 days of each 21 day cycle, plus have an infusion of pembrolizumab and trastuzumab into your vein on day 1 of each 21 day cycle.</div>
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary="You will have regular clinic visits:" />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="Before study treatment, to determine if the study is right for you" />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="During Study Treatment:" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Cycle 1 - Days 1 & 12 (Week 1 and towards the end of Week 2)" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Cycle 2 – Days 1 & 12 (Week 3 and towards the end of Week 5)" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Day 1 from Cycle 3 onwards (every 3 weeks from Week 7)." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="End of Treatment visit 30 days after stopping study treatment" />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="Follow Up:" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Every 6 or 9 weeks (to have scans), if you stopped study treatment and the cancer has not got worse" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Every 4 months to check your general health, if you stopped study treatment and the cancer has got worse. This may be done at a clinic visit, or via telephone or video conference." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary="The hospital will have procedures in place to minimise the risk from COVID-19 and make sure patients receive the treatment they need." />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} id="TugetherPlan">
                                <CustomAccordionSummary aria-controls="panel4d-content" id="panel4d-header" className={classes.accordionSummary}>
                                    <Typography>TUGETHER Trial Plan</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialPlanImage}
                                                alt={"Tugether Trial Plan"}
                                                style={{ objectFit: 'contain'}}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} id="TugetherTreatment">
                                <CustomAccordionSummary aria-controls="panel5d-content" id="panel5d-header" className={classes.accordionSummary}>
                                    <Typography>TUGETHER Trial Treatment</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialTreatmentImage}
                                                alt={"Tugether Trial Treatment"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} id="TugetherProcedures">
                                <CustomAccordionSummary aria-controls="panel6d-content" id="panel6d-header" className={classes.accordionSummary}>
                                    <Typography>TUGETHER Trial Procedures</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialProcedureImage}
                                                alt={"Tugether Trial Procedures"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} id="TugetherTimeline">
                                <CustomAccordionSummary aria-controls="panel7d-content" id="panel7d-header" className={classes.accordionSummary}>
                                    <Typography>TUGETHER Timeline</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialTimeLineImage}
                                                alt={"Tugether Timeline"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                        </div>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="TugetherNextSteps"
                        >
                            Next Steps
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please click <Link href={`/opms/picf/download/${invitation?.institutionCode}/${picf?.picfId}/${picf?.type.id}`} target="_blank" rel="noopener" className={classes.link}>here</Link> to review the full Participant Information Sheet/Consent Form to make sure you have all the information you need to make a decision.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please complete the form below if you are interested in more information about taking part in TUGETHER. You will be contacted by the study doctor or the study team at your hospital to discuss taking part.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            The study doctor and study team will answer any of your questions or concerns about TUGETHER, and help you understand the study procedures so you can decide if you want to take part.
                        </Typography> 
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please ask as many questions as you would like before agreeing to take part in the TUGETHER trial. You can also withdraw your consent at any time
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.eoiTitle}
                            id="TugetherEoi"
                        >
                            I would like to speak to the study team about this study
                        </Typography>
                        {
                            !eoiSubmitted && <EoiForm setEoiSubmitted={setEoiSubmitted}/>
                        }
                        {
                            !!eoiSubmitted && <div className={classes.submitSuccess}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={1}
                                        xl={1}
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} className={classes.submitSuccessIcon} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={10}
                                        lg={11}
                                        xl={11}
                                    >
                                        <div className={classes.submitSuccessMessage}>
                                            <span>Thank you for completing <br /> the Expression of Interest form</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="TugetherResources"
                        >
                            Resources
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            This study is being conducted by <Link href="https://www.breastcancertrials.org.au/home" target="_blank" rel="noopener" className={classes.link}>Breast Cancer Trials (BCT)</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href={require('../../../Documents/BCT 2102 TUGETHER Trial Summary.pdf')} target="_blank" rel="noopener" className={classes.link}>TUGETHER trial summary</Link> &ndash; click to download or print a summary of the TUGETHER trial
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/what-is-a-clinical-trial" target="_blank" rel="noopener" className={classes.link}>What is a Clinical Trial?</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/metastatic-breast-cancer-2/" target="_blank" rel="noopener" className={classes.link}>Metastatic/Advanced Breast Cancer</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.youtube.com/watch?v=9wk3PuugnYU&list=PLfaKgjeYDhnV2oWOsnZ9fxtMDQN9KErdQ&index=2" target="_blank" rel="noopener" className={classes.link}>What is informed consent</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            Ethics approval must be obtained for clinical trials involving human participation. Peter MaCallum Cancer Centre HREC has approved the TUGETHER Trial: HREC Reference HREC/86726/PMCC under  <Link href="https://www.clinicaltrialsandresearch.vic.gov.au/national-mutual-acceptance" target="_blank" rel="noopener" className={classes.link}>NMA</Link>.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            The TUGETHER Trial and participant materials have been endorsed by the BCT Consumer Advisory Panel.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            Participating in TUGETHER will contribute to clinical research, impacting future clinical research, standard practice for treatment, and future breast cancer patients.
                        </Typography>
                    </Grid>
                    {
                        !isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <div
                                    className={classes.quickAccessHeader}
                                >
                                    Quick Access
                                </div>
                                <div
                                    className={classes.quickAccessBody}
                                >
                                    <List>
                                        <ListItem component={Link} href={"#TugetherVideo"} className={classes.link}>
                                            <ListItemText primary="TUGETHER Information Video" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherPurpose"} className={classes.link}>
                                            <ListItemText primary="What is the Purpose of TUGETHER?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherToDo"} className={classes.link}>
                                            <ListItemText primary="What Do I Need to Do First?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherInvolve"} className={classes.link}>
                                            <ListItemText primary="What Does Taking Part in TUGETHER Involve?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherPlan"} className={classes.link}>
                                            <ListItemText primary="TUGETHER Trial Plan" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherTreatment"} className={classes.link}>
                                            <ListItemText primary="TUGETHER Trial Treatment" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherProcedures"} className={classes.link}>
                                            <ListItemText primary="TUGETHER Trial Procedures" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherTimeline"} className={classes.link}>
                                            <ListItemText primary="TUGETHER Timeline" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherNextSteps"} className={classes.link}>
                                            <ListItemText primary="Next Steps" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherEoi"} className={classes.link}>
                                            <ListItemText primary="Expression of Interest" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#TugetherResources"} className={classes.link}>
                                            <ListItemText primary="Resources" />
                                        </ListItem>
                                    </List>
                                </div>
                            </AppBar>
                        </Grid>
                    }
                </Grid>
            </div>
        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationPage;