/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    Select,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    KeyboardDatePicker,
    Numeric,
    PageLayout
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { makeStyles, Paper } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDemographicsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

const countryOfBirthOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.Demographics>) => GetFieldLookupItem(lookups, 'countryOfBirth', formState?.values.countryOfBirth)?.type === Dtos.LookupCountryType.Other;
const languageOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.Demographics>) => GetFieldLookupItem(lookups, 'primarySpokenLanguage', formState?.values.primarySpokenLanguage)?.type === Dtos.LookupLanguageType.Other;


/*
 * ----------------------------------------------------------------------------------
 * Styles
 * ----------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const Demographics: React.FunctionComponent<IDemographicsProps> = () => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const classes = useStyles();

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
            <CrfForm
                formType={Dtos.Demographics}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="gender"
                    component={Select}
                />
                <Field
                    name="dateOfBirth"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="participantInitials"
                    component={Text}
                />
                <Field
                    name="postcode"
                    component={Numeric}
                    numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="indigenousStatus"
                    component={Select}
                />
                <FieldGroup>
                    <Field
                        name="countryOfBirth"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.Demographics}
                        mode="Show"
                        condition={countryOfBirthOther}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="countryOfBirthOther"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="primarySpokenLanguage"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.Demographics}
                        mode="Show"
                        condition={languageOther}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="primarySpokenLanguageOther"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <SourceDocuments />
            </CrfForm>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Demographics;
