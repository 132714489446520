/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use screening log columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

import { makeStyles, Theme } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    textWrap: {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const getReasonValue = (reason: number | undefined) => {
    switch (reason) {
        case Dtos.LookupScreeningLogReasonType.ParticipantIneligible: { return 'Participant ineligible for registration'; }
        case Dtos.LookupScreeningLogReasonType.ClinicianDeemedUnsuitable: { return 'Clinician/MDT deemed unsuitable'; }
        case Dtos.LookupScreeningLogReasonType.ParticipantDeclinedTrialStandardCare: { return 'Participant declined trial because he/she prefers standard of care'; }
        case Dtos.LookupScreeningLogReasonType.ParticipantDeclinedTrialNoReason: { return 'Participant declined no reason given or not interested in the trial'; }
        default: { return ''; }
    }
}

export const getGenderValue = (gender: number | undefined) => {
    switch (gender) {
        case Dtos.LookupGenderType.Female: { return 'Female'; }
        case Dtos.LookupGenderType.Male: { return 'Male'; }
        case Dtos.LookupGenderType.NonBinary: { return 'Non-binary'; }
        default: { return ''; }
    }
}

const useScreenedParticipantsColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean): Array<Column<Dtos.ScreeningLog>> => {
    const classes = useStyles();

    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.ScreeningLog> | boolean> = [
            {
                field: 'screeningDate',
                title: 'Screening Date',
                render: (screeningLog, type) => (screeningLog.screeningDate ? DateTime.fromISO(screeningLog.screeningDate).toFormat('dd/MM/yyyy') : ''),
                cellStyle: {
                    minWidth: '160px'
                },
            },
            showSite && {
                field: 'institutionId',
                title: 'Site',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.id === (screeningLog.institutionId))?.name}</div>),
                customSort: (data1, data2, type) => !!institutions && ((institutions.find(i => i.id === (data1.institutionId)) as Dtos.IInstitution).name) < ((institutions.find(i => i.id === (data2.institutionId)) as Dtos.IInstitution).name) ? -1 : 1,
                cellStyle: {
                    minWidth: '175px'
                },
            },
            {
                field: 'initials',
                title: 'Initials',
                render: (screeningLog, type) => (screeningLog.initials),
                cellStyle: {
                    minWidth: '100px'
                },
            },
            {
                field: 'dateOfBirth',
                title: 'Date of Birth',
                render: (screeningLog, type) => (screeningLog.dateOfBirth ? DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy') : ''),
                cellStyle: {
                    minWidth: '150px'
                },
            },
            {
                field: 'gender',
                title: 'Gender',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{getGenderValue(screeningLog.gender)}</div>),
                cellStyle: {
                    minWidth: '120px'
                },
            },
            {
                field: 'reason',
                title: 'Reason',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{getReasonValue(screeningLog.reason)}</div>),
                customSort: (data1, data2, type) => (getReasonValue(data1.reason) < getReasonValue(data2.reason) ? -1 : 1),
                cellStyle: {
                    minWidth: '160px'
                },
            },
            //{
            //    field: 'patientId',
            //    title: 'Study Number',
            //    render: (screeningLog, type) => ((patients?.find(p => p.id === screeningLog.patientId) as Dtos.Patient)?.studyNumber),
            //    customSort: (data1, data2, type) => !!patients && ((patients?.find(p => p.id === data1.patientId) as Dtos.Patient)?.studyNumber ?? '') < ((patients?.find(p => p.id === data2.patientId) as Dtos.Patient)?.studyNumber ?? '') ? -1 : 1,
            //    cellStyle: {
            //        minWidth: '120px'
            //    }
            //}
        ];

        return cols.filter(column => !!column) as Array<Column<Dtos.ScreeningLog>>;
    }, [institutions, showSite, classes]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScreenedParticipantsColumns;
