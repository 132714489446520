import { Container, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Table, useAuthenticatedUser } from '@ngt/opms';
import { useHistory } from 'react-router-dom';
import { IReportParam, createReportUrl, ReportExportFormat } from '../utils/createReportUrl';
import { Column } from 'material-table';
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';

interface IReportsProps {
    institutionCode?: string
}

export interface IReportItem {
    reportType: number;
    reportTitle: string;
    reportIdentifier: string;
    reportParams?: IReportParam[];
    reportFormat?: ReportExportFormat;
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        display: 'flex',
        gap: 50
    },
    tableWrapper: {
        width: '100%'
    },
    padding: {
        padding: theme.spacing(0, 0, 2, 0)
    },
}));

const SSRS_REPORT = 1;
const OPMS_REPORT = 2;

const drugManagementPermissions: Dtos.Permission[] = [
    Dtos.Permission.DrugManagementView
];

const permissions: Dtos.Permission[] = [
    Dtos.Permission.EcrfView,
    Dtos.Permission.OpmsAdminister
];

const Reports: React.FunctionComponent<IReportsProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const [user] = useAuthenticatedUser();

    const [[canViewDrugManagement],] = usePermissionsByIds(drugManagementPermissions, null, null, null, null, false);

    const [[canViewEcrf, canAdministerOpms],] = usePermissionsByIds(permissions, null, null, null, null, true);

    const onOpmsReportClick = React.useCallback((report?: IReportItem | undefined) => {
        if (report) {
            history.push(`/${report.reportIdentifier}`);
        }
    }, [history]);

    const onSsrsReportClick = React.useCallback((report?: IReportItem | undefined) => {
        if (report) {
            const reportUrl: string = createReportUrl(report.reportIdentifier,
                report?.reportParams ?? [],
                report.reportFormat ? report.reportFormat as unknown as ReportExportFormat : undefined,
                true);

            window.open(reportUrl);
        }
    }, []);

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, row?: IReportItem | undefined) => {
        if (row?.reportType === SSRS_REPORT) {
            onSsrsReportClick(row);
        } else if (row?.reportType === OPMS_REPORT) {
            onOpmsReportClick(row);
        }
    }, [onSsrsReportClick, onOpmsReportClick]);

    const reportColumns = React.useMemo(() => {
        const columns: Array<Column<IReportItem>> = [
            {
                title: 'Reports',
                field: 'reportTitle',
            }
        ];

        return columns;
    }, []);

    const reports = React.useMemo(() => {
        return [
            !!canViewEcrf && {
                reportType: SSRS_REPORT,
                reportTitle: 'Forms Due Schedule',
                reportIdentifier: 'FormsDueSchedule',
                reportParams: [
                    { paramName: 'userKey', paramValue: 'EmailLink' }
                ]
            },
            //!!canViewEcrf && {
            //    reportType: OPMS_REPORT,
            //    reportTitle: 'Forms Due Summary',
            //    reportIdentifier: 'forms-due-summary'
            //},
            !!canViewEcrf && {
                reportType: SSRS_REPORT,
                reportTitle: 'Forms Due Summary',
                reportIdentifier: 'FormsDueSummary',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName },
                    { paramName: 'Institution', paramValue: '-1' },
                    { paramName: 'Patient', paramValue: '-1' }
                ]
            },
            !!canViewEcrf && {
                reportType: SSRS_REPORT,
                reportTitle: 'Forms Overdue',
                reportIdentifier: 'FormsOverdue',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName },
                    { paramName: 'Institution', paramValue: '-1' },
                    { paramName: 'Patient', paramValue: '-1' }
                ]
            },
            !!canViewDrugManagement && {
                reportType: SSRS_REPORT,
                reportTitle: 'Batch Report',
                reportIdentifier: 'BatchReport',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName }
                ]
            },
            !!canAdministerOpms && {
                reportType: SSRS_REPORT,
                reportTitle: 'Depot Stock',
                reportIdentifier: 'DepotStock',
                reportParams: [
                    { paramName: 'UserKey', paramValue: 'EmailLink' }
                ]
            },
            !!canViewDrugManagement && {
                reportType: SSRS_REPORT,
                reportTitle: 'Drug Management Log',
                reportIdentifier: 'DrugManagementLog',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName }
                ]
            },
            !!canViewDrugManagement && {
                reportType: SSRS_REPORT,
                reportTitle: 'Drug Shipment Report',
                reportIdentifier: 'DrugShipmentReport',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName }
                ]
            },
            !!canAdministerOpms && {
                reportType: SSRS_REPORT,
                reportTitle: 'Monthly SAE Report',
                reportIdentifier: 'MonthlySaeReport',
            },
            !!canViewDrugManagement && {
                reportType: SSRS_REPORT,
                reportTitle: 'Patient Action Report',
                reportIdentifier: 'PatientActionReport',
                reportParams: [
                    { paramName: 'UserKey', paramValue: user?.userName }
                ]
            },
        ].filter(x => !!x)?.sort((a: IReportItem, b: IReportItem) => a.reportTitle < b.reportTitle ? -1 : 1) as IReportItem[];
    }, [canViewDrugManagement, canAdministerOpms, user]);

    return (
        <Container>

            <div
                className={classes.container}
            >
                <div
                    className={classes.tableWrapper}
                >
                    <Typography
                        className={classes.padding}
                        color="secondary"
                        variant="h1"
                    >
                        Reports
                    </Typography>
                    <Table
                        data={reports}
                        columns={reportColumns}
                        onRowClick={onRowClick}
                        title="Reports"
                        components={{
                            Header: props => <></>
                        }}
                        options={{
                            paging: reports.length > 10,
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

export default Reports;
