/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    FormBreadcrumbs,
    FormGrid,
    getParentPropertyPath,
    ICrfConditionParameters,
    IFormGridCell,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PageLayout,
    PatientContext,
    RouteLoading,
    Select,
    Text
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { makeStyles, Paper, Typography } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { get } from 'lodash-es';
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ITumourBlockSubmissionProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3),
        fontSize: '1rem',

        '.MuiContainer-maxWidthLg': {
            maxWidth: '100% !important'
        }
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const isSampleSiteOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.TumourBlock>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.TumourBlock = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.sampleSite === Dtos.LookupSampleSiteType.Other;
};

// const isSampleBlock = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.TumourBlock>) => {
//     // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
//     const parentPropertyPath = getParentPropertyPath(parentFieldName);

//     if (!parentPropertyPath) {
//         return false;
//     }

//     const row: Dtos.TumourBlock = get(formState?.values, parentPropertyPath);

//     if (!row) {
//         return false;
//     }

//     return row.ffpeBlockOrSlides === Dtos.LookupFfpeType.Block;
// };

const isSampleSlides = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.TumourBlock>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.TumourBlock = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.ffpeBlockOrSlides === Dtos.LookupFfpeType.Slides;
};

const tumourBlockColumns: Array<IFormGridCell<Dtos.TumourBlock>> = [
    {
        name: 'sampleNumber',
        minWidth: 80,
        maxWidth: 80,
        width: 80,
        content: (
            <Input
                component={Numeric}
                disabled={true}
            />
        )
    },
    {
        name: 'sampleType',
        minWidth: 150,
        maxWidth: 200,
        width: 150,
        content: (
            <Input
                component={Select}
                style={{ maxWidth: '200px' }}
            />
        )
    },
    {
        name: 'sampleSite',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Select}
                style={{ maxWidth: '150px' }}
            />
        )
    },
    {
        name: 'sampleSiteSpecify',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <CrfCondition
                type={Dtos.TumourBlock}
                condition={isSampleSiteOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'dateCollected',
        minWidth: 205,
        maxWidth: 205,
        width: 205,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'ffpeBlockOrSlides',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Select}
                style={{ maxWidth: '140px' }}
            />
        )
    },
    {
        name: 'dateSlidesWereCut',
        minWidth: 205,
        maxWidth: 205,
        width: 205,
        content: (
            <CrfCondition
                type={Dtos.TumourBlock}
                condition={isSampleSlides}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={KeyboardDatePicker}
                />
            </CrfCondition>
        )
    },
    {
        name: 'tumourBlockId',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'slideIdStart',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        content: (
            <CrfCondition
                type={Dtos.TumourBlock}
                condition={isSampleSlides}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Numeric}
                    numberFormat={{ allowNegative: false, decimalScale: 0 }}
                />
            </CrfCondition>
        )
    },
    {
        name: 'slideIdEnd',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        content: (
            <CrfCondition
                type={Dtos.TumourBlock}
                condition={isSampleSlides}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Numeric}
                    numberFormat={{ allowNegative: false, decimalScale: 0 }}
                />
            </CrfCondition>
        )
    }
];


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const TumourBlockTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h6"
                className={classes.instruction}
            >
                <strong>Instructions:</strong> Please enter the details of the tumour samples to be submitted for central assessment of PD-L1, TILs status and correlative research. These details will be used to populate the Pathology Requisition Form, which will be automatically sent via email to the Principal Investigator and Site Coordinator together with the participant number upon finalisation of pre-registration. The Pathology Requisition Form and tumour samples are then required to be shipped to the Central Laboratory at Peter MacCallum Cancer Centre. Please refer to the Pathology Manual for Pathology Shipment requirements.
            </Typography>
            <FormGrid
                type={Dtos.TumourBlock}
                name="tumourBlocks"
                columns={tumourBlockColumns}
                onAdd={(values) => {
                    const gridSize = values?.length ?? 0;

                    let value = new Dtos.TumourBlock({ sampleNumber: (gridSize + 1), patientStateId: Dtos.PatientStateType.NewPatient });

                    if (values) {
                        return [...values, value];
                    }

                    return [value];

                }}
                onDelete={(values, index) => {
                    if (values === undefined) {
                        return;
                    }

                    if (index >= values?.length) {
                        return;
                    }

                    values = values?.filter((v, i) => index !== i);

                    values.forEach((value, index) => {
                        return value.sampleNumber = (index + 1);
                    });

                    return values;
                }}
                rowLabel="Sample"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].sampleNumber`);

                        return `Sample Number ${rowValue}`;
                    }

                    return undefined;
                }}
                //allowAdd={!(formValues?.tumourBlocks?.length >= 2)}
            />
        </>
    );
}

const TumourBlockSubmission: React.FunctionComponent<ITumourBlockSubmissionProps> = () => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const classes = useStyles();

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <PageLayout
            breadcrumbs={<FormBreadcrumbs />}
        >
            <CrfForm
                formType={Dtos.TumourBlockSubmission}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <TumourBlockTbl />
                <SourceDocuments />
            </CrfForm>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default TumourBlockSubmission;
